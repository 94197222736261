import Link from 'next/link';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import {
  ContactSection,
  ContactSectionCardDesc,
  ContactSectionCardTitle,
  ContactSectionContainer,
  ContactSectionDesc,
  ContactSectionGradient,
  ContactSectionGradient2,
  ContactSectionTitle,
  ContactUsBodyContainer,
  ContactUsCard,
  ContactUsCardLeft,
  ContactUsContentWrapper,
  ContactUsLeft,
  ContactUsWrapper,
  CreateTicketButton,
  EmailText,
  IconBox,
  TicketSectionDesc,
  TicketSectionTitle,
} from '../../styles/pageStyles/contactStyle';

import Head from 'next/head';
import styled from 'styled-components';
import { MaskIcon } from '../../components/Icons/Icons';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import { getUserInfoSSR } from '../../utils/cf';

const H1Title = styled.h1`
  font: normal normal bold 32px/36px Onest-Bold;
  margin: 20px 0;
  color: ${(props) => props.theme.palette.text.t1};
`;

const H2Title = styled.h2`
  font: normal normal bold 22px/30px Onest-Bold;
  margin: 15px 0;
  color: ${(props) => props.theme.palette.text.t2};
`;

// interface Props {
//   meta: string;
// }

const Contact = () => {
  const { messages } = useLocale();
  const {
    issues_with_purchase_msg,
    best_way_resolve_ticket_msg,
    business_development_msg,
    legal_department_msg,
    talent_team_msg,
    contact_us_msg,
    for_all_questions_or_information_msg,
    for_clarification_or_assistance_with_msg,
    are_you_seeking_a_new_msg,
    need_any_help_or_got_msg,
    create_a_ticket_msg,
  } = messages || {};

  const ContactUsList = [
    {
      title: business_development_msg,
      desc: for_all_questions_or_information_msg,
      icon: '/icons/business.svg',
      email: 'business@driffle.com',
    },
    {
      title: legal_department_msg,
      desc: for_clarification_or_assistance_with_msg,
      icon: '/icons/legal.svg',
      email: 'legal@driffle.com',
    },
    {
      title: talent_team_msg,
      desc: are_you_seeking_a_new_msg,
      icon: '/icons/profile-24.svg',
      email: 'careers@driffle.com',
    },
    // {
    //   title: contact_us_msg,
    //   desc: ' Bluecut Limited (13227089) +1 (628) 222-4131 35 First Avenue, London, England, N11 3NE',
    //   icon: '/icons/phone_msg.svg',
    //   email: 'hello@driffle.com',
    // },
  ];
  return (
    <>
      <Head>
        <title>Contact Us - Driffle </title>
      </Head>
      <Header />
      <Navbar />
      {/* <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <Header type="about" headerColor="translucent" />
        <Navbar type="about" />
      </div> */}

      <ContactUsWrapper>
        <ContactSectionGradient />
        <ContactSectionGradient2 />
        <ContactUsBodyContainer>
          <ContactSectionContainer>
            <ContactSection>
              <ContactSectionTitle>{contact_us_msg}</ContactSectionTitle>
              <ContactSectionDesc>
                {need_any_help_or_got_msg}
              </ContactSectionDesc>
              <ContactUsContentWrapper>
                {ContactUsList.map((item, index) => {
                  return (
                    <ContactUsCard key={index}>
                      <IconBox>
                        <MaskIcon
                          url={item.icon}
                          margin=" 0 0 0 0"
                          height="20px"
                          width="20px"
                          selected
                          color={COLORS.white}
                        />
                      </IconBox>
                      <ContactUsCardLeft>
                        <ContactSectionCardTitle>
                          {/* <MaskIcon
                      url={item.icon}
                      margin="0 6px 0 0"
                      height="24px"
                      width="24px"
                      selected
                      color={COLORS.white}
                    />{' '} */}
                          {item.title}
                        </ContactSectionCardTitle>
                        <ContactSectionCardDesc>
                          {item.desc}
                        </ContactSectionCardDesc>
                        <EmailText
                          href={`mailto:${item.email}`}
                          target="_blank"
                        >
                          {item.email}
                        </EmailText>
                      </ContactUsCardLeft>
                    </ContactUsCard>
                  );
                })}
              </ContactUsContentWrapper>
            </ContactSection>

            <ContactUsLeft>
              <TicketSectionTitle>
                {issues_with_purchase_msg}
              </TicketSectionTitle>
              <TicketSectionDesc>
                {best_way_resolve_ticket_msg}
              </TicketSectionDesc>
              <Link passHref prefetch={false} href="/support">
                <a>
                  <CreateTicketButton>{create_a_ticket_msg}</CreateTicketButton>
                </a>
              </Link>
            </ContactUsLeft>
          </ContactSectionContainer>
        </ContactUsBodyContainer>
      </ContactUsWrapper>

      <Footer />
    </>
  );
};

export default Contact;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
