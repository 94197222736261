import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const ContactUsContainer = styled.div`
  display: flex;
  background: #0c0c0c;
  flex-direction: column;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 87px;
  width: 100%;
  // margin: auto;

  padding: 0 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 0;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  // margin-top:163px;
  justify-content: center;
  align-items: center;
`;

//vats
export const ContactUsBodyContainer = styled.div`
  margin: auto;
  // height: calc(100vh);
  width: 100%;

  // overflow-y: scroll;
  // scroll-snap-type: y mandatory;
  // section {
  //   height: calc(100vh);
  //   width: 100%;
  //   // scroll-snap-align: start;
  // }
  ::-webkit-scrollbar {
    display: none;
  }
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // @media (max-width: ${WIDTH.tabletMax}) {
  //   height: calc(100vh);
  //   section {
  //     height: calc(100vh);
  //   }
  // }
  @media (max-width: ${WIDTH.mobileMax}) {
    // height: calc(100vh);
    // margin-bottom:115px;
    // section {
    //   height: calc(100vh);
    // }
  }
`;
//vats
export const ContactUsWrapper = styled.div`
  overflow: hidden;
  background: #0c0c0c;
  @media (min-width: 1440px) {
    margin: auto;
  }

  // height: calc(100vh);
`;
export const ContactUsCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ContactUsContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  gap: 24px;
  // margin-left:124px;
  // margin-right:124px;
  padding: 0 16px;
  margin-top: 97px;
  z-index: 10;
  // @media (max-width: ${WIDTH.tabletMax}) {
  //   gap: 16px;
  //   overflow-x: scroll;
  //   justify-content: flex-start;

  //   flex-wrap: nowrap;
  //   margin: 0;
  //   ::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 16px;
    overflow-y: scroll;
    // justify-content: flex-start;
    // display:flex;
    flex-direction: column;
    // flex-wrap: nowrap;
    // margin: 0;
    margin-top: 24px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const ContactUsLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // margin-bottom:115px;
  // padding-right: 40px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

//vats
export const ContactUsTitle = styled.div`
  font: 64px Onest-Bold;
  color: #fff;
  margin-top: 100px;

  font-size: 64px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: 0em;
  text-align: left;

  text-align: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    font: 2rem/2.25rem Onest-Bold;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 32px Onest-Bold;
  }
`;

export const ContactUsDesc = styled.div`
  font: 1rem/1.25rem Onest-Medium;
  color: #9f9f9f;
  text-align: center;
  padding: 0 16px;
`;

// export const ContactUsRight = styled.div`
//   // width: 50%;
//   width:100%;
//   // border-left: 1px solid ${(props) => props.theme.palette.divider.d1};
//   padding-left: 40px;
//   padding-top: 40px;
//   @media (max-width: 767px) {
//     width: 100%;
//     border-left: none;
//     // border-top: 1px solid ${(props) => props.theme.palette.divider.d1};
//     padding-left: 0;
//     margin-top: 40px;
//   }
// `;

export const ContactSectionTitle = styled.div`
  font-family Onest-Bold;
  font-size: 64px;
  color: #fff;
  margin-top:100px;
  position: relative;
  
  text-align: center;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 32px Onest-Bold;
  }
`;

export const ContactSectionDesc = styled.div`
  font: 1rem/1.25rem Onest-Medium;
  color: #9f9f9f;
  text-align: center;
  padding: 0 32px;

  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 14px;
    margin-top: 24px;
  }
`;

export const ContactSectionCardTitle = styled.div`
  font: 0.875rem/1.125rem Onest-Bold;
  color: #fff;
  z-index: 10;
`;
export const ContactSectionCardDesc = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: #ffffff99;
`;
// export const ContactList = styled.ul`
//   display:flex;
//   gap:24px;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   margin: 24px 0 0;
//   list-style: none;

// `;

// export const ContactListItem = styled.li`
//   margin-bottom: 40px;
//   max-width: 352px;
//   max-height:182px
//   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
//   z-index: 10;
//    padding: 24px;
//   background: #121212b2;
//   border-radius: 16px;

// `;

export const ContactUsCard = styled.div`
  position: relative;
  width: 352px;
  height: 182px;
  display: flex;
  flex-direction: row;
  gap: 23px;

  padding: 24px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  z-index: 10;
  background: #121212b2;
  border-radius: 16px;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1px;
    background: linear-gradient(145deg, #c8c8c88f, #00000066);
    backdrop-filter: blur(60px);
    -webkit-mask: linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
  }
`;

export const ListItemName = styled.div`
  font-style: normal;

  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};

  // margin-bottom: 10px;
`;

export const ListItemBody = styled.div`
  font-size: 16px;

  // margin-bottom: 10px;
  color: ${(props) => props.theme.palette.text.t2};
`;

export const EmailText = styled.a`
  font-size: 1rem;
  color: black;
  color: ${(props) => props.theme.palette.text.primary};
  font-family: Onest-SemiBold;
`;

export const CreateTicketButton = styled.button`
border-radius: 24px;
background: rgba(68, 68, 68, 0.30);
width: 178px;
height: 48px;
margin-bottom:115px;
color:#fff;
border: 1px solid;

// border-image-source: linear-gradient(0deg, #CBCBCB, #CBCBCB);



margin-top:48px;
font-family: Onest-Regular;
@media (max-width: ${WIDTH.mobileMax}) {
  width: 147px
  height: 40px
 
  
}

`;

export const TicketSectionTitle = styled.div`
  color: #fff;
  font-family: Onest-Bold;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  padding: 0 32px;
`;

export const TicketSectionDesc = styled.div`
  color: #8f8f8f;
  text-align: center;
  font-family: Onest-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 0 32px;
  margin-top: 24px;
`;

export const IconBox = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(90, 90, 90, 0.2);
`;

export const ContactSectionGradient = styled.div`
  position: absolute;
  width: 800px;
  height: 900px;
  top: -400px;
  left: 0;
  right: 0;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  background: radial-gradient(
    72.86% 50% at 72.86% 50%,
    #5d21a9 0%,
    rgba(23, 23, 23, 0) 100%
  );
  filter: blur(80px);
  transform: rotate(-90deg);
  pointer-events: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 200px;
    height: 500px;
    top: -75px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 150px;
    height: 350px;
    top: -100px;
  }
`;
export const ContactSectionGradient2 = styled.div`
  position: absolute;
  width: 400px;
  height: 500px;
  top: -200px;
  left: 0;
  right: 0;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
  background: radial-gradient(
    72.86% 50% at 72.86% 50%,
    #213fa9 0%,
    rgba(23, 23, 23, 0) 100%
  );
  filter: blur(80px);
  transform: rotate(-90deg);
  pointer-events: none;

  @media (max-width: ${WIDTH.tabletMax}) {
    width: 140px;
    height: 300px;
    top: -75px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100px;
    height: 250px;
    top: -75px;
  }
`;
